<template>
  <div @click="showSwiper = false">
    <el-dialog
      custom-class="swiper-dialog"
      :visible.sync="showSwiper"
      :fullscreen="true"
      :close-on-click-modal="true"
      :show-close="false"
      @closed="closePage"
    >
      <div class="swiper-box" @click.stop>
        <div
          class="before btn"
          @click="before"
        ></div>
        <img
          class="swiper-img"
          :src="imageList[showIndex].picUrl"
        >
        <div
          class="next btn"
          @click="next"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    imageList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      showIndex: 0,
      showSwiper: true
    }
  },
  methods: {
    closePage () {
      this.$emit('close')
    },
    before () {
      if (this.showIndex <= 0) {
        this.showIndex = this.imageList.length - 1;
        return;
      }
      this.showIndex--
    },
    next () {
      if (this.showIndex >= this.imageList.length - 1) {
        this.showIndex = 0;
        return;
      }
      this.showIndex++
    }
  }
}
</script>

<style lang="scss" scope>
.swiper-dialog {
  background: transparent;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .swiper-box {
    position: relative;
    // margin-top: 15vh;
    .swiper-img {
      min-width: 500px;
      max-width: 890px;
      min-height: 40vh;
      max-height: 80vh;
    }
    .btn {
      position: absolute;
      cursor: pointer;
      top: 50%;
      left: 25px;
      margin-top: -35px;
      width: 70px;
      height: 70px;
      background-size: 70px 70px !important;
      background-repeat: no-repeat !important;
      background-color: red;
      border-radius: 70px;
      &.before {
        background: url('../../../assets/medicine/img_left_arrow@2x.png')
      }
      &.next {
        left: auto;
        right: 25px;
        background: url('../../../assets/medicine/img_right_arrow@2x.png')
      }
    }
  }
}
</style>