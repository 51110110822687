<template>
  <div class="medic-info">
    <div class="medic-container flex-start-start">
      <div class="info-left" id="medicLeft" v-noCopy>
        <!-- 面包屑 -->
        <div class="content breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              ><a
                href="#/medicine/patentMedicine/searchContent?medicType=2&searchType=-1&searchText="
                >中成药</a
              ></el-breadcrumb-item
            >
            <!-- <a href="/"></a> -->
            <el-breadcrumb-item>中成药详情页</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 名称 -->
        <div class="content info-name-box">
          <div class="name-wrapper flex-start-center">
            {{ patentInfo.commonName }}
            <Tag style="margin-left: 6px" :productItem="patentInfo" />
          </div>
          <!--  id="modifyDate" v-anchor="'#modifyDate'" -->
          <div
            class="sub-name"
            v-if="patentInfo.modifyDate && !patentInfo.approveDate"
          >
            药监局最后修改日期：{{ patentInfo.modifyDate }}
          </div>
          <!--  id="approveDate" v-anchor="'#approveDate'" -->
          <div
            class="sub-name"
            v-else-if="!patentInfo.modifyDate && patentInfo.approveDate"
          >
            药监局最后核准日期：{{ patentInfo.approveDate }}
          </div>
          <!--  id="modifyDate" v-anchor="'#modifyDate'" -->
          <div
            class="sub-name"
            v-else-if="
              patentInfo.modifyDate &&
              patentInfo.approveDate &&
              showDate == 'modifyDate'
            "
          >
            药监局最后修改日期：{{ patentInfo.modifyDate }}
          </div>
          <!-- id="approveDate" v-anchor="'#approveDate'" -->
          <div
            class="sub-name"
            v-else-if="
              patentInfo.modifyDate &&
              patentInfo.approveDate &&
              showDate == 'approveDate'
            "
          >
            药监局最后核准日期：{{ patentInfo.approveDate }}
          </div>
        </div>
        <!-- 基本资料 -->
        <div class="info-title-box" id="information" v-anchor="'#information'">
          <div class="info-title flex-start-center" style="margin-bottom: 14px">
            <span>基本资料</span>
            <div class="line flex-1"></div>
          </div>
          <div class="flex-start-center">
            <div class="content information">
              <div class="box flex-start-center" v-if="patentInfo.commonName">
                <span class="name">通用名称：</span>
                <span class="text flex-1">{{
                  patentInfo.commonName | removeHtml
                }}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.cnName">
                <span class="name">商品名称：</span>
                <span class="text flex-1">{{
                  patentInfo.cnName | removeHtml
                }}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.enName">
                <span class="name">英文名称：</span>
                <span class="text flex-1">{{
                  patentInfo.enName | removeHtml
                }}</span>
              </div>
              <!-- <div class="box flex-start-center" v-if="patentInfo.component">
                <span class="name">成分：</span>
                <span class="text flex-1">{{patentInfo.component | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.description">
                <span class="name">性状：</span>
                <span class="text flex-1">{{patentInfo.description | removeHtml}}</span>
              </div> -->
              <div class="box flex-start-center" v-if="patentInfo.form">
                <span class="name">规格：</span>
                <span class="text flex-1">{{
                  patentInfo.form | removeHtml
                }}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.storage">
                <span class="name">贮藏：</span>
                <span class="text flex-1">{{
                  patentInfo.storage | removeHtml
                }}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.period">
                <span class="name">有效期：</span>
                <span class="text flex-1">{{
                  patentInfo.period | removeHtml
                }}</span>
              </div>
              <!-- <div class="box flex-start-center" v-if="patentInfo.pack">
                <span class="name">包装：</span>
                <span class="text flex-1">{{patentInfo.pack | removeHtml}}</span>
              </div> -->
              <div
                class="box flex-start-center"
                v-if="
                  patentInfo.poisonDrugPriceList &&
                  patentInfo.poisonDrugPriceList.length > 0
                "
              >
                <span class="name">药品价格：</span>
                <span class="text ellipsis flex-1 flex-start-center">
                  {{ patentInfo.poisonDrugPriceList[0].specification }}
                  <el-dropdown
                    placement="bottom"
                    v-if="patentInfo.poisonDrugPriceList.length > 1"
                  >
                    <span class="price-info flex-start-center">
                      ¥{{
                        patentInfo.poisonDrugPriceList[0].price | changeMoney
                      }}
                      <img
                        style="margin-left: 6px"
                        src="@/assets/medicine/price_Tishi@2x.png"
                        alt=""
                      />
                    </span>
                    <el-dropdown-menu class="medic-drop" slot="dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in patentInfo.poisonDrugPriceList"
                        :key="index"
                        :divided="index > 1"
                        v-if="index > 0"
                      >
                        <span class="num">{{ item.specification }}</span>
                        <span class="price"
                          >¥{{ item.price | changeMoney }}</span
                        >
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
            <div class="flex-1 flex-center-center">
              <div
                class="image-box"
                @click="showDialog"
                v-if="
                  patentInfo.pictureList && patentInfo.pictureList.length > 0
                "
              >
                <img
                  :src="item.picUrl"
                  v-for="(item, index) in patentInfo.pictureList"
                  :key="index"
                  v-if="item.ifCover == 1"
                  alt=""
                />
                <p class="more medic-hover-info">更多图片</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 毒性研究 -->
        <div
          class="info-title-box"
          id="duStudy"
          v-anchor="'#duStudy'"
          v-if="
            patentInfo.toxicDrug ||
            patentInfo.toxicInn ||
            patentInfo.poisoningMechanism ||
            patentInfo.poisoningAppearance ||
            patentInfo.poisoningCause ||
            patentInfo.poisoningRescue
          "
        >
          <div class="info-title dashed flex-start-center">
            <span>毒性研究</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <div class="toxicity">
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.toxicDrug"
              >
                <span class="name">有毒草药：</span>
                <span class="text flex-1" v-html="patentInfo.toxicDrug"></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.toxicInn"
              >
                <span class="name">有毒成分：</span>
                <span class="text flex-1" v-html="patentInfo.toxicInn"></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.poisoningMechanism"
              >
                <span class="name">中毒机理：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.poisoningMechanism"
                ></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.poisoningAppearance"
              >
                <span class="name">中毒表现：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.poisoningAppearance"
                ></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.poisoningCause"
              >
                <span class="name">致死剂量：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.poisoningCause"
                ></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.poisoningRescue"
              >
                <span class="name">中毒处理：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.poisoningRescue"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 成分 -->
        <div
          class="info-title-box"
          id="component"
          v-anchor="'#component'"
          v-if="patentInfo.component"
        >
          <div class="info-title flex-start-center">
            <span>成分</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.component"></p>
          </div>
        </div>
        <!-- 性状 -->
        <div
          class="info-title-box"
          id="description"
          v-anchor="'#description'"
          v-if="patentInfo.description"
        >
          <div class="info-title flex-start-center">
            <span>性状</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.description"></p>
          </div>
        </div>
        <!-- 功能主治 -->
        <div
          class="info-title-box"
          id="indication"
          v-anchor="'#indication'"
          v-if="patentInfo.indication"
        >
          <div class="info-title flex-start-center">
            <span>功能主治（适应症）</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.indication"></p>
          </div>
        </div>
        <!-- 用法用量 -->
        <div
          class="info-title-box"
          id="dosage"
          v-anchor="'#dosage'"
          v-if="patentInfo.dosage"
        >
          <div class="info-title flex-start-center">
            <span>用法用量</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.dosage"></p>
          </div>
        </div>
        <!-- 服用与进食 -->
        <div
          class="info-title-box"
          id="administration"
          v-anchor="'#administration'"
          v-if="patentInfo.administration"
        >
          <div class="info-title flex-start-center">
            <span>服用与进食</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.administration"></p>
          </div>
        </div>
        <!-- 特殊人群用药 -->
        <div
          class="info-title-box"
          id="population"
          v-anchor="'#population'"
          v-if="
            patentInfo.useInPregLact ||
            patentInfo.innFda ||
            patentInfo.innLrc ||
            patentInfo.useInChildren ||
            patentInfo.useInElderly
          "
        >
          <div class="info-title dashed flex-start-center">
            <span>特殊人群用药</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <div class="toxicity">
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.useInPregLact"
              >
                <span class="name">孕妇及哺乳期妇女用药：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.useInPregLact"
                ></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.innFda">
                <span class="name">妊娠等级：</span>
                <span class="text flex-1" v-html="patentInfo.innFda"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.innLrc">
                <span class="name">哺乳等级：</span>
                <span class="text flex-1" v-html="patentInfo.innLrc"></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.useInChildren"
              >
                <span class="name">儿童用药：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.useInChildren"
                ></span>
              </div>
              <div
                class="box flex-column-start-start"
                v-if="patentInfo.useInElderly"
              >
                <span class="name">老年用药：</span>
                <span
                  class="text flex-1"
                  v-html="patentInfo.useInElderly"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 不良反应 -->
        <div
          class="info-title-box"
          id="adverseReactions"
          v-anchor="'#adverseReactions'"
          v-if="patentInfo.adverseReactions"
        >
          <div class="info-title flex-start-center">
            <span>不良反应</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.adverseReactions"></p>
          </div>
        </div>
        <!-- 用药须知 -->
        <div
          class="info-title-box"
          id="cautions"
          v-anchor="'#cautions'"
          v-if="patentInfo.cautions"
        >
          <div class="info-title flex-start-center">
            <span>用药须知</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.cautions"></p>
          </div>
        </div>
        <!-- 禁忌 -->
        <div
          class="info-title-box"
          id="contraindications"
          v-anchor="'#contraindications'"
          v-if="patentInfo.contraindications"
        >
          <div class="info-title flex-start-center">
            <span>禁忌</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.contraindications"></p>
          </div>
        </div>
        <!-- 注意事项 -->
        <div
          class="info-title-box"
          id="precautions"
          v-anchor="'#precautions'"
          v-if="patentInfo.precautions"
        >
          <div class="info-title flex-start-center">
            <span>注意事项</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.precautions"></p>
          </div>
        </div>
        <!-- 警告 -->
        <div
          class="info-title-box"
          id="warning"
          v-anchor="'#warning'"
          v-if="patentInfo.warning"
        >
          <div class="info-title flex-start-center">
            <span>警告</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.warning"></p>
          </div>
        </div>
        <!-- 药物相互作用 -->
        <div
          class="info-title-box"
          id="drugInteractions"
          v-anchor="'#drugInteractions'"
          v-if="patentInfo.drugInteractions"
        >
          <div class="info-title flex-start-center">
            <span>药物相互作用</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.drugInteractions"></p>
          </div>
        </div>
        <!-- 药理作用 -->
        <div
          class="info-title-box"
          id="mechanismAction"
          v-anchor="'#mechanismAction'"
          v-if="patentInfo.mechanismAction"
        >
          <div class="info-title flex-start-center">
            <span>药理作用</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.mechanismAction"></p>
          </div>
        </div>
        <!-- 药代动力学 -->
        <div
          class="info-title-box"
          id="pharmacokinetics"
          v-anchor="'#pharmacokinetics'"
          v-if="patentInfo.pharmacokinetics"
        >
          <div class="info-title flex-start-center">
            <span>药代动力学</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.pharmacokinetics"></p>
          </div>
        </div>
        <!-- 药物过量 -->
        <div
          class="info-title-box"
          id="overdosage"
          v-anchor="'#overdosage'"
          v-if="patentInfo.overdosage"
        >
          <div class="info-title flex-start-center">
            <span>药物过量</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.overdosage"></p>
          </div>
        </div>
        <!-- 临床试验 -->
        <div
          class="info-title-box"
          id="clinicalTrial"
          v-anchor="'#clinicalTrial'"
          v-if="patentInfo.clinicalTrial"
        >
          <div class="info-title flex-start-center">
            <span>临床试验</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.clinicalTrial"></p>
          </div>
        </div>
        <!-- 包装 -->
        <div
          class="info-title-box"
          id="pack"
          v-anchor="'#pack'"
          v-if="patentInfo.pack"
        >
          <div class="info-title flex-start-center">
            <span>包装</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.pack"></p>
          </div>
        </div>
        <!-- 执行标准 -->
        <div
          class="info-title-box"
          id="standard"
          v-anchor="'#standard'"
          v-if="patentInfo.standard"
        >
          <div class="info-title flex-start-center">
            <span>执行标准</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.standard"></p>
          </div>
        </div>
        <!-- 企业信息 -->
        <div
          class="info-title-box"
          id="enterprise"
          v-anchor="'#enterprise'"
          v-if="patentInfo.companyName || patentInfo.approveCode"
        >
          <div class="info-title dashed flex-start-center">
            <span>企业信息</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <div class="toxicity fade">
              <div class="box flex-start-center" v-if="patentInfo.companyName">
                <span class="name">生产企业：</span>
                <span
                  class="text ellipsis flex-1"
                  v-html="patentInfo.companyName"
                ></span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.approveCode">
                <span class="name">批准文号：</span>
                <span
                  class="text ellipsis flex-1"
                  v-html="patentInfo.approveCode"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 锚点 -->
      <div class="info-right">
        <ul class="info-anchor">
          <li
            class="item"
            :class="{ active: anchorIndex == item.href }"
            v-for="(item, index) in anchorList"
            :key="index"
            v-if="!item.hidden"
          >
            <!-- :href="item.href" -->
            <a class="medic-hover-info" @click="goHref(item)">{{
              item.name
            }}</a>
            <ul class="sun-panel" v-if="item.subList">
              <li
                class="panel-item medic-hover-info"
                v-for="(child, childIndex) in item.subList"
                :key="childIndex"
                v-if="!child.hidden"
              >
                {{ child.name }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <component
      :is="model"
      :imageList="patentInfo.pictureList"
      @close="model = null"
    ></component>
  </div>
</template>

<script>
import Tag from '@/views/herbalMedicine/components/tag'
import DialogSwiper from '@/views/herbalMedicine/components/DialogSwiper'
import { patentDrugInfo } from '@/api/herbalMedicine'
export default {
  data() {
    return {
      showDate: 'modifyDate',
      anchorIndex: '#information',
      model: null,
      patentInfo: {},
      anchorList: {
        // modifyDate: {
        //   href: '#modifyDate',
        //   name: '修改日期',
        // },
        // approveDate: {
        //   href: '#approveDate',
        //   name: '核准日期',
        // },
        information: {
          href: '#information',
          name: '基本资料',
          subList: {
            cnName: { name: '商品名称' },
            commonName: { name: '通用名称' },
            enName: { name: '英文名称' },
            // component: {name: '成分'},
            // description: {name: '性状'},
            form: { name: '规格' },
            storage: { name: '贮藏' },
            period: { name: '有效期' },
            // pack: {name: '包装'},
            poisonDrugPriceList: { name: '药品价格' },
          }
        },
        duStudy: {
          href: '#duStudy',
          name: '毒性研究',
          // '有毒草药', '有毒成分', '中毒机理', '中毒表现', '中毒原因', '中毒处理'
          subList: {
            toxicDrug: { name: '有毒草药' },
            toxicInn: { name: '有毒成分' },
            poisoningMechanism: { name: '中毒机理' },
            poisoningAppearance: { name: '中毒表现' },
            poisoningCause: { name: '致死剂量' },
            poisoningRescue: { name: '中毒处理' },
          }
        },
        component: {
          href: '#component',
          name: '成分',
        },
        description: {
          href: '#description',
          name: '性状',
        },
        indication: {
          href: '#indication',
          name: '功能主治（适应症）',
        },
        dosage: {
          href: '#dosage',
          name: '用法用量',
        },
        administration: {
          href: '#administration',
          name: '服用与进食',
        },
        population: {
          href: '#population',
          name: '特殊人群用药',
          // '孕妇及哺乳期妇女用药', '妊娠等级', '哺乳等级', '儿童用药', '老年用药'
          subList: {
            useInPregLact: { name: '孕妇及哺乳期妇女用药' },
            innFda: { name: '妊娠等级' },
            innLrc: { name: '哺乳等级' },
            useInChildren: { name: '儿童用药' },
            useInElderly: { name: '老年用药' }
          }
        },
        adverseReactions: {
          href: '#adverseReactions',
          name: '不良反应',
        },
        cautions: {
          href: '#cautions',
          name: '用药须知',
        },
        contraindications: {
          href: '#contraindications',
          name: '禁忌',
        },
        precautions: {
          href: '#precautions',
          name: '注意事项',
        },
        warning: {
          href: '#warning',
          name: '警告',
        },
        drugInteractions: {
          href: '#drugInteractions',
          name: '药物相互作用',
        },
        mechanismAction: {
          href: '#mechanismAction',
          name: '药理作用',
        },
        pharmacokinetics: {
          href: '#pharmacokinetics',
          name: '药代动力学',
        },
        overdosage: {
          href: '#overdosage',
          name: '药物过量',
        },
        clinicalTrial: {
          href: '#clinicalTrial',
          name: '临床试验',
        },
        pack: {
          href: '#pack',
          name: '包装',
        },
        standard: {
          href: '#standard',
          name: '执行标准',
        },
        enterprise: {
          href: '#enterprise',
          name: '企业信息',
          subList: {
            companyName: { name: '生产企业' },
            approveCode: { name: '批准文号' },
          }
        },
      },
      anchorIdList: [],
      anchorScroll: null, // 滚动距离顶部高度
    }
  },
  mounted() {
    this.initInfo();
    this.$nextTick(res => {
      window.onscroll = e => {
        this.anchorScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        Object.keys(this.anchorIdList).map(res => {
          if (this.anchorScroll >= this.anchorIdList[res]) {
            this.anchorIndex = res;
          }
        })
      }
    })
  },
  directives: {
    anchor: {
      inserted(el, { value }, vnode) {
        if (value) {
          let _this = vnode.context;
          _this.anchorIdList[value] = el.offsetTop
        }
      }
    }
  },
  methods: {
    initInfo() {
      console.log('查询中成药详情数据')
      patentDrugInfo({ drugId: this.$route.params.drugId }).then(res => {
        console.log(res)
        this.patentInfo = res;
        this.hiddenAnchor();
      }).catch(error => {
        this.$message.error(error.msg)
      })
    },
    // 处理锚点显示问题
    hiddenAnchor() {
      Object.keys(this.anchorList).map(item => {
        Object.keys(this.patentInfo).map(patent => {
          // 有子集
          if (this.anchorList[item].subList) {
            Object.keys(this.anchorList[item].subList).map(child => {
              // 没有相关字段 或没值
              if (!this.patentInfo[child]) {
                this.anchorList[item].subList[child].hidden = true;
              }
              if (Array.isArray(this.patentInfo[child])) {
                this.anchorList[item].subList[child].hidden = true;
              }
            })
          }
          // 特殊处理
          if (item !== 'information' && item !== 'duStudy' && item !== 'population' && item !== 'enterprise') {
            if (!this.patentInfo[item])  // 返回字段没值 隐藏
              this.anchorList[item].hidden = true;
          } else {
            // 初始化父节点不显示
            let hidden = true;
            Object.keys(this.anchorList[item].subList).map(child => {
              // 找到有显示节点 父节点就显示
              if (!this.anchorList[item].subList[child].hidden)
                hidden = false
              this.anchorList[item].hidden = hidden;
            })
          }
        })
      })
      // 处理默认显示锚点
      for (var item in this.anchorList) {
        if (!this.anchorList[item].hidden) {
          this.anchorIndex = this.anchorList[item].href
          break;
        }
      }
      // 核准日期与修改日期
      let modifyDate = ''
      let approveDate = ''
      modifyDate = this.patentInfo.modifyDate.replace(/[年月]/g, '-');
      modifyDate = modifyDate.replace(/日/g, '');
      modifyDate = new Date(modifyDate).getTime();

      approveDate = this.patentInfo.approveDate.replace(/[年月]/g, '-');
      approveDate = approveDate.replace(/日/g, '');
      approveDate = new Date(approveDate).getTime();
      if (modifyDate <= approveDate) {
        this.showDate = 'approveDate'
      } else {
        this.showDate = 'modifyDate'
      }
    },
    showDialog() {
      this.model = DialogSwiper;
    },
    goHref(item) {
      document.querySelector(item.href) && document.querySelector(item.href).scrollIntoView(true);
      this.anchorIndex = item.href
    }
  },
  filters: {
    ellipsis(val) {
      if (val && val.length > 15 * 2) {
        val = val.substring(0, 15 * 2) + '...'
      }
      return val
    },
    changeMoney(val) {
      if (!val) return
      val = String(val)
      return val.slice(0, val.length - 2) + '.' + val.slice(val.length - 2, val.length)
    }
  },
  components: {
    Tag,
    DialogSwiper
  },
  watch: {
    $route(to) {
      location.reload();
    }
  },
  beforeDestroy() {
    window.onscroll = () => { }
  }
}
</script>

<style lang="scss" scope>
@import "./../layout/info.scss";
</style>